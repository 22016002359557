import {
  Button,
  FloatingLabel,
  Form,
  Offcanvas,
  Card,
  Col,
  Modal,
} from "react-bootstrap";
import SMSLogo from "../../../assets/images/socialChannels/whatsapp.svg";
import { useEffect, useState } from "react";
import TableLoader from "../../Common/TableLoader/TableLoader";
import HelperService from "../../../Services/HelperService";
import { useForm } from "react-hook-form";
import { Label } from "../../Common/Label/Label";
import { TooltipCustom } from "../../Common/Tooltip/Tooltip";
import WebService from "../../../Services/WebService";
import { toast } from "react-toastify";
import moment from "moment";
import { BiDownload } from "react-icons/bi";
import NoImage from "../../../assets/images/noImage.png"
import { GrAttachment } from "react-icons/gr";
import { useSelector } from "react-redux";
import { AiOutlineSend } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";

interface PropData {
  id?: any;
  socialType?: any;
  automark?: any;
  sendData?: any;
  testData?: any;
  post?: any;
  sendSMSData?: any;
  parentCallback?: any;
  selectedTab?: any;
  isReviewed?: any;
  indexItem?: any;
  nextItem?: any;
  isPreview?: any;
  isFromPreviewModal?: any;
}

const MessageViewBlade = (props: PropData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const role = useSelector((state: any) => state.uR.role);
  const [reviewFlag, setReviewFlag] = useState(false);
  let [changedPost, setChangedPost] = useState<any>(null);
  const [showMore, setShowMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const [smsMedia, setSmsMedia] = useState<any>();
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    watch,
  } = useForm();
  var watchVariable = watch();
  const [complainceUserLogAccess, setComplainceUserLogAccess] = useState(true);
  useEffect(() => {
    var data1 = atob(localStorage.getItem('ucd') ?? "");
    if (role === "COMPLIANCE_OFFICER") {
      if ((data1 === "" || data1 === undefined)) {
      } else {
        var json = JSON.parse(data1);
        setComplainceUserLogAccess(json.reviewLogs)
      }
    }
  }, []);

  useEffect(() => {
    if (props.post?.id !== undefined)
      getSmsDetails();
  }, [props.post]);

  useEffect(() => {
    if (props.post !== null) {
      setShowMore(false);
      setLoader(true);
      setChangedPost(props.post);
      watchVariable.notes = props.post.notes ?? "";
      setValue("notes", props.post.notes ?? "");
      watchVariable.markedAsFlag = props.post.markedAsFlag ?? false;
      setValue("markedAsFlag", props.post.markedAsFlag ?? false);
      watchVariable.markedAsView = props.post.markedAsView ?? false;
      setValue("markedAsView", props.post.markedAsView ?? false);
      watchVariable.escalateAndSend = props.post.escalateAndSend ?? false;
      setValue("escalateAndSend", props.post.escalateAndSend ?? false);

      if (props.automark && !props.post.markedAsView) {
        watchVariable.markedAsView = true;
        setValue("markedAsView", true);
        handleAddNotes(props.post, true);
      }
    }
    setLoader(false);
  }, [props.post, props.automark]);

  const handleAddNotes = (post?: any, showSuccess?: any) => {
    if (role === "BASIC_USER") {
      return false;
    }
    if (!complainceUserLogAccess) {
      return false;
    }
    if (post.id !== undefined) {
      changedPost = JSON.parse(JSON.stringify(post));
    } else {
      changedPost = JSON.parse(JSON.stringify(props.post))
    }

    if (
      props.automark && props.post.markedAsView
      && props.post.notes === watchVariable.notes
      && props.post.markedAsFlag === watchVariable.markedAsFlag
      && props.post.escalateAndSend === watchVariable.escalateAndSend
    ) {
      setValue("markedAsView", true);
      return false
    }

    if (!props.isFromPreviewModal) {
      WebService.addLoader(`whatsapp-note-save-btn`);
    } else {
      WebService.addLoader(`whatsapp-note-save-btn1`);
    }

    watchVariable.id = props.post.id;
    let reviewClass = "";
    if (watchVariable.markedAsFlag) {
      reviewClass = "flag";
    }
    if (watchVariable.markedAsView) {
      reviewClass = reviewClass + " " + "mardk-as-viewed";
    }
    if (watchVariable.escalateAndSend) {
      reviewClass = reviewClass + " " + "escalate-send";
    }
    if (changedPost.reviewClass !== null) {
      changedPost.reviewClass = reviewClass;
    }

    changedPost.markedAsFlag = watchVariable.markedAsFlag;
    changedPost.markedAsView = watchVariable.markedAsView;
    changedPost.escalateAndSend = watchVariable.escalateAndSend;
    changedPost.notes = watchVariable.notes;
    return WebService.putAPI({
      action: `whatsapp/message`,
      body: watchVariable,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        if (!props.automark || !showSuccess || showSuccess?.type === "submit") {
          toast.success(res.message);
        }
        var obj = {
          changedPost: changedPost,
          post: post.id !== undefined ? post : props.post
        };
        props.isReviewed(true);
        props.sendSMSData(obj);
        if (!props.isFromPreviewModal) {
          WebService.removeLoader(`whatsapp-note-save-btn`);
        } else {
          WebService.removeLoader(`whatsapp-note-save-btn1`);
        }
      })
      .catch((error: any) => {
        toast.error(error.message);
        if (!props.isFromPreviewModal) {
          WebService.removeLoader(`whatsapp-note-save-btn`);
        } else {
          WebService.removeLoader(`whatsapp-note-save-btn1`);
        }
        return error;
      });
  };
  const handleError = () => { };

  const handleConversation = () => {
    props.parentCallback(true, props.post)
  }

  const getSmsDetails = () => {
    return WebService.getAPI({
      action: `whatsapp-message?id=${props.post?.id}`,
      body: null,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        // if (props.selectedTab !== "MY_REPCHAT" && props.selectedTab !== "RING_CENTRAL") {
        setSmsMedia(res);
        // } else {
        //   setSmsMedia(res.attchments);
        // }

      })
      .catch((error: any) => {
        return error;
      });
  };



  return (
    <>
      {!loader && props.post !== null ? (
        <Form
          className="w-100 form-style h-100"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit(handleAddNotes, handleError)}
        >
          <Offcanvas.Body className="px-0 py-3">
            <div className="px-3 mb-3">
              <div className="d-flex align-items-center gap-2">
                <div>
                  <img src={SMSLogo} width={50} height={50} alt="" />
                </div>
                <div>
                  <p className="font-16 font-medium mb-0 ">WhatsApp</p>
                  <p className="mb-1 text-secondary font-12 text-nowrap">
                    {moment(props.post.messageDateTime)
                      .local()
                      .format("MM-DD-YY hh:mm A ")}
                  </p>
                  <span className={props.post.senderType === "INBOUND" ? "status-badge active" : "status-badge active-2"}>
                    {props.post.senderType}
                  </span>
                </div>
                {
                  !props.isFromPreviewModal &&
                  <Col>
                    <div onClick={handleConversation} style={{ cursor: "pointer", marginLeft: "115px", color: "#0000EE" }} className="conversation font-14">Conversation</div>
                    <div className="d-flex justify-content-end ms-auto">
                      <Button
                        className="text-nowrap action-btn btn-brand-1 font-12 px-3 py-1 me-2 nextbutton"
                        title="Next"
                        onClick={() => {
                          props.nextItem(props.indexItem);
                        }}
                      >
                        <AiOutlineSend className="align-text-center" size={11} style={{ marginRight: '5px' }} />
                        Next
                      </Button>
                      <a href="javascript:void(0)" className="text-brand cursor-pointer" onClick={() => {
                        props.isPreview(true);
                      }}>
                        {props.post ? (<BsBoxArrowUpRight size={22} />) : ("")}
                      </a>
                    </div>
                  </Col>
                }

              </div>
            </div>
            <hr className=" border-secondary-subtle" />
            <div className="px-3">
              <Card className="border-0">
                <Card.Header className="gap-3" style={{ borderColor: "#ddd" }}>
                  <div className="text-secondary mb-0 col-6">
                    <div className="font-medium text-dark">
                      <p className="text-secondary mb-0 font-12">Send By:</p>
                      <span className="font-14">{props.post.senderDisplayName}</span> <br />
                      <span className="font-14">{props.post.fromNumber}</span>
                    </div>{" "}
                  </div>
                  <div className="mb-0 col-6">
                    <div className="font-medium text-dark">
                      <p className="text-secondary mb-0 font-12"> Received By:</p>
                      <span className="font-14 mb-0">{props.post.recieverName}</span> <br />
                      <span className="font-14"> {props.post.toNumber}</span>
                      {" "}
                    </div>
                  </div>
                </Card.Header>

                <Card.Body className="bg-light">
                  <div className="font-14">
                    <span dangerouslySetInnerHTML={{ __html: HelperService.getHighlightedContent(props.post?.message, props.post?.highlighted) }}></span>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {
              smsMedia?.mediaType === "IMAGE" ?
                <Card className="border-0 mb-2 px-3 py-2 rounded-4 chat-corner-right chat-card">
                  <img onClick={() => window.open(smsMedia?.mediaPath)} className='rounded-3 img-fluid object-fit-contain cursor-pointer' style={{ maxHeight: "200px" }} src={smsMedia?.mediaPath}
                    onError={(error: any) => {
                      error.target.src = NoImage;
                    }} />
                </Card>
                : smsMedia?.mediaType === "VIDEO" ?
                  <Card className="border-0 mb-2 px-3 py-2 rounded-4 chat-corner-right chat-card">
                    <video width="320" height="240" controls>
                      <source src={smsMedia?.mediaPath} type="video/mp4" />
                    </video>
                  </Card>
                  : ""
            }

            <>
              <hr className=" border-secondary-subtle" />
              <div className="px-3 mb-3">
                <div className="d-flex gap-2 mb-1 text-nowrap">
                  <div className="px-2 py-1 bg-danger-subtle">
                    <Form.Check
                      {...register("markedAsFlag")}
                      type="checkbox"
                      id={!props.isFromPreviewModal ? `whatsapp-flag` : `whatsapp-flag1`}
                      label="Flag"
                      className="labe-text-dark checkbox-danger"
                      checked={watchVariable.markedAsFlag}
                      disabled={role !== "BASIC_USER" ? false : true}
                      onChange={() => {
                        if (watchVariable.markedAsFlag) {
                          watchVariable.markedAsFlag = false;
                          setValue("markedAsFlag", false);
                          setReviewFlag(false);
                        } else {
                          watchVariable.markedAsFlag = true;
                          setValue("markedAsFlag", true);
                        }
                        handleAddNotes(props.post, true);
                      }}
                    />
                  </div>
                  <div className="px-2 py-1 bg-success-subtle">
                    <Form.Check
                      type="checkbox"
                      {...register("markedAsView")}
                      id={!props.isFromPreviewModal ? `whatsapp-markedAsView` : `whatsapp-markedAsView1`}
                      label="Mark as viewed"
                      className="labe-text-dark checkbox-success"
                      checked={watchVariable.markedAsView}
                      disabled={role !== "BASIC_USER" ? false : true}
                      onChange={() => {
                        if (watchVariable.markedAsView) {
                          watchVariable.markedAsView = false;
                          setValue("markedAsView", false);
                          setReviewFlag(false);
                        } else {
                          watchVariable.markedAsView = true;
                          setValue("markedAsView", true);
                        }
                        handleAddNotes(props.post, true);
                      }}
                    />
                  </div>


                  <div className="px-2 py-1 bg-warning-subtle">
                    <Form.Check
                      type="checkbox"
                      {...register("escalateAndSend")}
                      id={!props.isFromPreviewModal ? `whatsapp-escalateAndSend` : `whatsapp-escalateAndSend1`}
                      label="Escalate send"
                      className="labe-text-dark checkbox-warning"
                      checked={watchVariable.escalateAndSend}
                      disabled={role !== "BASIC_USER" ? false : true}
                      onChange={() => {
                        if (watchVariable.escalateAndSend) {
                          watchVariable.escalateAndSend = false;
                          setValue("escalateAndSend", false);
                          setReviewFlag(false);
                        } else {
                          watchVariable.escalateAndSend = true;
                          setValue("escalateAndSend", true);
                        }
                        handleAddNotes(props.post, true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className=" border-secondary-subtle" />
              <div className=" form-style">
                <div className="px-3">
                  <FloatingLabel controlId="Notes" label="Notes">
                    <Form.Control
                      {...register("notes")}
                      as="textarea"
                      id={!props.isFromPreviewModal ? `whatsapp-note` : `whatsapp-note1`}
                      placeholder="Notes"
                      style={{ height: "100px" }}
                      value={watchVariable.notes ? watchVariable.notes : ""}
                      disabled={role !== "BASIC_USER" ? false : true}
                    />
                    {errors.notes && (
                      <Label
                        title="Please add notes"
                        modeError={true}
                        showStar={true}
                        type=""
                      />
                    )}
                  </FloatingLabel>
                  {
                    complainceUserLogAccess && role !== "BASIC_USER" ?
                      <div className="mt-3 text-center">
                        <Button
                          type="submit"
                          className={!props.isFromPreviewModal ? "btn btn-brand-1 w-100" : "btn btn-brand-1 w-50"}
                          id={!props.isFromPreviewModal ? `whatsapp-note-save-btn` : `whatsapp-note-save-btn1`}
                        >
                          Add Note
                        </Button>
                      </div>
                      : ""
                  }
                </div>
              </div>
            </>
          </Offcanvas.Body>
        </Form >
      ) : (
        <TableLoader />
      )}


    </>
  );
};
export default MessageViewBlade;
