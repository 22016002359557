import { Col } from "react-bootstrap";
import "./card.scss";
interface PropData {
    lastFourDigit: any;
    expMonth: any;
    expYear: any;
    card: string;
    id: string
    sendId: any
    type:any
}

const CreditCard = (props: PropData) => {
    return (
        <Col md={6}>
            <div className={"credit-card selectable " + props.card} onClick={() => { props.sendId(props.id,props.type) }}>
                <div className="credit-card-last4 font-14">
                    {props.lastFourDigit}
                </div>
                <div className="credit-card-expiry">
                    {props.expMonth}/{props.expYear}
                </div>
            </div>
        </Col>

    )
}
export default CreditCard;