import {
    Offcanvas,
    Card,
    Col,
    Row,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import WebService from "../../Services/WebService";
import TableLoader from "../Common/TableLoader/TableLoader";
import HelperService from "../../Services/HelperService";
import moment from "moment";
import NoImage from "../../assets/images/noImage.png"
import NoDataFound from "../../assets/images/empty_item.svg";
import { BiDownload } from "react-icons/bi";
import { TooltipCustom } from "../Common/Tooltip/Tooltip";
import { useSelector } from "react-redux";

interface PropData {
    smsConversationData?: any;
    parentCallback?: any;
    selectedTab?: any;
    replyId?: any;
    id?: any;
}

const BloombergConversationBlade = (props: PropData) => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const [loader, setLoader] = useState(false);
    const [bloombergChatList, setBloombergChatList] = useState<any>([]);
    const {
        formState: { errors },
        watch,
    } = useForm();

    useEffect(() => {
        getBloombergChatList();
    }, []);

    const getBloombergChatList = () => {
        if (!props.replyId) {
            return false;
        }
        setLoader(true);
        return WebService.getAPI({
            action: `bloomberg/message?page=1&id=${props.id}&thread_id=${props.replyId}`,
            body: null,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                if (res.list.length > 0) {
                    setBloombergChatList(res.list)
                }
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                return error;
            });
    };

    const handleError = () => { };

    return (
        <>

            <Offcanvas.Body className="px-0 py-3">
                <div className="px-3">
                    {
                        !loader
                            ?
                            bloombergChatList.length > 0 ?
                                <div className="chat-box h-auto">
                                    {
                                        bloombergChatList?.map((item: any, index: any) => {
                                            return (
                                                <Row className="mb-3 mx-0">
                                                    <Col lg={8} className="d-flex gap-2 align-items-end">

                                                        <div>
                                                            {
                                                                item?.fileType === "FILE" ?
                                                                    <>
                                                                        {
                                                                            item?.message ?
                                                                                <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                    {item?.text}
                                                                                </Card>
                                                                                : ""

                                                                        }

                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                            <Card className="p-4 border-brand h-100  text-center cursor-pointer" onClick={() => window.open(item?.mediaPath)} >
                                                                                <BiDownload size={30} className="mb-3 mx-auto icon" />
                                                                                <p className="font-500 font-16 mb-0">
                                                                                    <TooltipCustom
                                                                                        st={
                                                                                            <span>{HelperService.truncate(item?.fileName, 15)}</span>
                                                                                        }
                                                                                        message={item?.fileName}
                                                                                        position="top"
                                                                                        id="file"
                                                                                    />
                                                                                </p>
                                                                            </Card>
                                                                        </Card>
                                                                        <div className="d-flex gap-3 justify-content-end">
                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                {moment(item.messageCreatedTime)
                                                                                    .local()
                                                                                    .format("MM-DD-YY hh:mm A")}
                                                                            </p>
                                                                        </div>

                                                                    </>
                                                                    : item?.fileType === "IMAGE" ?
                                                                        <>
                                                                            <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                <img onClick={() => window.open(item?.mediaPath)} className='rounded-3 img-fluid object-fit-contain cursor-pointer'
                                                                                    style={{ maxHeight: "200px" }}
                                                                                    src={item?.mediaPath}
                                                                                    onError={(error: any) => {
                                                                                        error.target.src = NoImage;
                                                                                    }} />
                                                                            </Card>
                                                                            <div className="d-flex gap-3 justify-content-end">

                                                                                <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                    {moment(item.messageCreatedTime)
                                                                                        .local()
                                                                                        .format("MM-DD-YY hh:mm A")}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                        : item?.fileType === "VIDEO" ?
                                                                            <>
                                                                                <Card onClick={() => window.open(item?.mediaPath)} className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                    <video className='rounded-3 img-fluid object-fit-contain cursor-pointer' style={{ maxHeight: "200px" }} controls>
                                                                                        <source src={item?.mediaPath} type="video/mp4" />
                                                                                    </video>
                                                                                </Card>
                                                                                <div className="d-flex gap-3 justify-content-end">

                                                                                    <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                        {moment(item.messageCreatedTime)
                                                                                            .local()
                                                                                            .format("MM-DD-YY hh:mm A")}
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                            : item?.fileType === "AUDIO" ?
                                                                                <>
                                                                                    <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                        <audio onClick={() => window.open(item?.mediaPath)} className='rounded-3 cursor-pointer' controls>
                                                                                            <source src={item?.mediaPath} type="audio/mpeg" />
                                                                                        </audio>
                                                                                    </Card>
                                                                                    <div className="d-flex gap-3 justify-content-end">

                                                                                        <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                            {moment(item.messageCreatedTime)
                                                                                                .local()
                                                                                                .format("MM-DD-YY hh:mm A")}
                                                                                        </p>
                                                                                    </div>
                                                                                </>
                                                                                : item?.fileType === "TEXT_MESSAGE" ?
                                                                                    <>
                                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                            <div dangerouslySetInnerHTML={{ __html: HelperService.getHighlightedContent(item?.message, item?.highlighted) }}></div>
                                                                                        </Card>
                                                                                        <div className="d-flex gap-3 justify-content-end">

                                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                                {moment(item.messageCreatedTime)
                                                                                                    .local()
                                                                                                    .format("MM-DD-YY hh:mm A")}
                                                                                            </p>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                            {/* {item.text} */}
                                                                                            <div dangerouslySetInnerHTML={{ __html: item?.text }}></div>
                                                                                        </Card>
                                                                                        <div className="d-flex gap-3 justify-content-end">

                                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                                {moment(item.messageCreatedTime)
                                                                                                    .local()
                                                                                                    .format("MM-DD-YY hh:mm A")}
                                                                                            </p>
                                                                                        </div>
                                                                                    </>

                                                            }


                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }

                                </div>
                                :
                                <div className="text-center" style={{ marginTop: "140px" }}>
                                    <img
                                        src={NoDataFound}
                                        width={110}
                                        className="mb-3"
                                        alt={""}
                                    />
                                    <h3 className="text-secondary font-12">
                                        No Conversation Found
                                    </h3>
                                </div>
                            : <TableLoader />
                    }
                </div>

            </Offcanvas.Body>

        </>
    );
};
export default BloombergConversationBlade;


