import {
  Button,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import HelperService from "../../Services/HelperService";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import FbLogo from "../../assets/images/fb-logo.svg";
import Xlogo from "../../assets/images/socialChannels/twitter_icon.svg";
import InstgramLogo from "../../assets/images/insta-logo.svg";
import linkdnLogo from "../../assets/images/linkedin-logo.svg";
import youtubeLogo from "../../assets/images/socialChannels/youtube-logo.svg";
import stocktwitsLogo from "../../assets/images/socialChannels/stacktwits-icon.png";
import NoImage from "../../assets/images/noImage.png";
import WMDateRangePicker from "../Common/WMDateRangePicker/WMDateRangePicker";
import { TooltipCustom } from "../Common/Tooltip/Tooltip";

interface propsData {
  reviewId: any;
  type: any;
  websiteId?: any
}
const ReviewAllFilterBlade = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [connectors, setConnectors] = useState<any>([]);
  const [allConnectors, setAllConnectors] = useState<any>([]);
  const [connectorError, setConnectorsError] = useState<any>("");
  const [aiFlaggingEnabled, setAiFlaggingEnabled] = useState<boolean>(false);
  const [date_range, setdateRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    setError,
    control,
    unregister,
  } = useForm();

  var watchVariable = watch();

  const [checkbox, setCheckbox] = useState({
    Flagged: false,
    Reviewed: false,
    flaggedNotReviewed: false,
    escalateAndSend: false,
    notReviewed: false,
  });

  useEffect(() => {
    watchVariable.websiteId = props.websiteId;
    setValue("websiteId", props.websiteId);
    watchVariable.startDate = startDate;
    setValue("startDate", startDate);
    watchVariable.endDate = endDate;
    setValue("endDate", endDate);
    watchVariable.percentage = 10;
    setValue("percentage", 10);
  }, []);

  const handleReviewAll = () => {
    if (watchVariable.percentage !== "") {
      clearErrors("percentage");
    } else {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.startDate !== "") {
      clearErrors("startDate");
    } else {
      setError("startDate", {
        type: "custom",
        message: "Please select start date",
      });
    }
    if (watchVariable.endDate !== "") {
      clearErrors("endDate");
    } else {
      setError("endDate", {
        type: "custom",
        message: "Please select end date",
      });
    }
    const enteredStartDate = new Date(watchVariable.startDate);
    const enteredEndDate = new Date(watchVariable.endDate);
    const newArray = connectors?.map((item: any) => {
      if (item.value !== "SMS" && item.value !== "MY_REPCHAT" && item.value !== "WHATSAPP" && item.value !== "ZOOM_SMS" && item.value !== "IMESSAGE" && item.value !== "EMAIL") {
        let social_type = item;
        let account_type = "";

        if (item.value.includes('PERSONAL')) {
          social_type = item.value.replace('_PERSONAL', '');
          account_type = 'PERSONAL';
        } else if (item.value.includes('BUSINESS')) {
          social_type = item.value.replace('_BUSINESS', '');
          account_type = 'BUSINESS';
        } else {
          social_type = item.value;
          account_type = '';
        }
        return {
          socialType: social_type,
          account_type: account_type,
          idList: getIdList(social_type + account_type)
        };
      } else {
        let social_type = item;
        let account_type = "";

        if (item.value.includes('PERSONAL')) {
          social_type = item.value.replace('_PERSONAL', '');
          account_type = 'PERSONAL';
        } else if (item.value.includes('BUSINESS')) {
          social_type = item.value.replace('_BUSINESS', '');
          account_type = 'BUSINESS';
        } else {
          social_type = item.value;
          account_type = '';
        }
        return {
          socialType: social_type,
          account_type: account_type,
          sender: item.value === "EMAIL" ? emailSenders.filter((value: string) => value !== "") : senders.filter((value: string) => value !== ""),
          recipient: item.value === "EMAIL" ? emailReceivers.filter((value: string) => value !== "") : receivers.filter((value: string) => value !== ""),
        };
      }

    });
    if (connectors?.length === 0) {
      setConnectorsError("Please select channels");
      return false;
    }

    if (enteredStartDate > enteredEndDate) {
      setError("endDate", {
        type: "custom",
        message: "End date must be greater than start date",
      });
      return;
    } else {
      clearErrors("endDate");
    }

    delete watchVariable.emailSender;
    delete watchVariable.emailReceiver;
    delete watchVariable.smsSender;
    delete watchVariable.smsReceiver;

    let modifiedArray = newArray.filter((ele: any) => ele.socialType !== "OTHER_SMS");

    let userArr: any = [];
    let grpArr: any = [];
    selectedOptions.forEach((reviewer: any) => {
      if (reviewer.category === "user") {
        userArr.push(reviewer.value);
      } else {
        grpArr.push(reviewer.value);
      }
    });

    WebService.addLoader("btn-save");
    return WebService.postAPI({
      action: `review`,
      body: {
        ...watchVariable,
        "percentage": watchVariable.sampleReview === "PERCENTAGE" ? parseInt(watchVariable.percentage) : null,
        "sampleCount": watchVariable.sampleReview === "COUNT" ? parseInt(watchVariable.percentage) : null,
        "aiFlagging": aiFlaggingEnabled,
        "accounts": modifiedArray,
        "request_type": "",
        "accountType": "",
        "markedAsFlag": checkbox?.Flagged,
        "markedAsView": checkbox?.Reviewed,
        "escalateAndSend": checkbox?.escalateAndSend,
        "notReviewed": checkbox?.notReviewed,
        "flaggedNotReviewed": checkbox?.flaggedNotReviewed,
        "userList": userArr,
        "groupList": grpArr,

      },
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        WebService.removeLoader("btn-save");
        props.reviewId(res.uuid, watchVariable.startDate, watchVariable.endDate, aiFlaggingEnabled);
      })
      .catch((error: any) => {
        WebService.removeLoader("btn-save");
        return error;
      });
  };
  
  const handleError = (errors: any) => {
    if (
      watchVariable.percentage === "" ||
      watchVariable.percentage === undefined
    ) {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.name === "" || watchVariable.name === undefined) {
      setError("name", {
        type: "custom",
        message: "Please enter name",
      });
    }
    if (
      watchVariable.percentage === "" ||
      watchVariable.percentage === undefined
    ) {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.startDate !== "") {
      clearErrors("startDate");
    } else {
      setError("startDate", {
        type: "custom",
        message: "Please select start date",
      });
    }
    if (watchVariable.endDate !== "") {
      clearErrors("endDate");
    } else {
      setError("endDate", {
        type: "custom",
        message: "Please select end date",
      });
    }

    if (connectors?.length === 0) {
      setConnectorsError("Please select channels");
      return false;
    }

    const enteredStartDate = new Date(watchVariable.startDate);
    const enteredEndDate = new Date(watchVariable.endDate);
    if (enteredStartDate > enteredEndDate) {
      setError("endDate", {
        type: "custom",
        message: "End date must be greater than start date",
      });
    } else {
      clearErrors("endDate");
    }
  };
  const handleChange = (type: any, value: any) => {
    if (type === "name") {
      watchVariable.name = value;
      setValue("name", value);
      if (watchVariable.name !== "") {
        clearErrors("name");
      } else {
        setError("name", {
          type: "custom",
          message: "Please enter name",
        });
      }
    } else if (type === "percentage") {
      watchVariable.percentage = value;
      setValue("percentage", value);
      if (watchVariable.percentage !== "") {
        clearErrors("percentage");
      } else {
        setError("percentage", {
          type: "custom",
          message: "Please enter percentage",
        });
      }
    } else if (type === "startDate") {
      watchVariable.startDate = value;
      setValue("startDate", value);
      if (watchVariable.startDate !== "") {
        clearErrors("startDate");
      } else {
        setError("startDate", {
          type: "custom",
          message: "rt",
        });
      }
    } else if (type === "endDate") {

      watchVariable.endDate = value;
      setValue("endDate", value);
      if (watchVariable.endDate !== "") {
        clearErrors("endDate");
      } else {
        setError("endDate", {
          type: "custom",
          message: "er",
        });
      }
    }
  };
  const onDateChange = (date: any, type: any) => {
    if (type === "startDate") {
      setStartDate(date);
      watchVariable.startDate = date;
      setValue("startDate", date);
    } else if (type === "endDate") {
      setEndDate(date);
      watchVariable.endDate = date;
      setValue("endDate", date);
    }
  };


  const connectorPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      WebService.postAPI({
        action: `user-integration/all-connectors`,
        // user-integration/list  
        body: {},
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.length > 0) {
            const modifiedIntegrationDetails: any[] = [];
            res.map((channel: any) => {
              if (
                // channel.type === "INSTAGRAM" ||
                // channel.type === "LINKEDIN" ||
                channel.type === "X"
              ) {
                let hasBusinessAccount = false;
                let hasPersonalAccount = false;

                if (Array.isArray(channel?.list)) {
                  channel?.list?.forEach((item: any) => {
                    if (item.accountType === "BUSINESS") {
                      hasBusinessAccount = true;
                    }
                    if (item.accountType === "PERSONAL") {
                      hasPersonalAccount = true;
                    }
                  });
                }

                if (hasBusinessAccount) {
                  const businessIntegration = { value: channel.type + "_" + "BUSINESS", label: channel.type + " " + "BUSINESS" };
                  modifiedIntegrationDetails.push(businessIntegration);
                }

                if (hasPersonalAccount) {
                  const personalIntegration = { value: channel.type + "_" + "PERSONAL", label: channel.type + " " + "PERSONAL" };
                  modifiedIntegrationDetails.push(personalIntegration);
                }
              } else {
                modifiedIntegrationDetails.push({ value: channel.type, label: channel.type === "SMS" ? "ANDROID SMS" : channel.type.replaceAll("_", " ") });
              }
            });

            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...modifiedIntegrationDetails];
            setAllConnectors(options);
            resolve(options);
          } else {
            setAllConnectors([]);
            resolve([]);
          }
        })
        .catch((error: any) => {
          setAllConnectors([]);
          resolve([]);
          return error;
        });
    });

  const onConnectorSelection = (selectedOptions: any) => {
    setConnectorsError("");
    const isSelectAllSelected = selectedOptions.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];


    if (isSelectAllSelected) {
      arr = allConnectors
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOptions;
    }

    setConnectors(arr);
  };





  //Email
  const [emailSenders, setEmailSenders] = useState<any>([]);
  const [emailReceivers, setEmailReceivers] = useState<any>([]);

  const handleEmailSendersChange = (e: any) => {
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setEmailSenders(values);
  };


  const handleEmailReceiversChange = (e: any) => {
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setEmailReceivers(values);
  };

  //SMS  / MyRepChat / iMessage / Zoom SMS
  const [senders, setSenders] = useState<any>([]);
  const [receivers, setReceivers] = useState<any>([]);

  const handleSendersChange = (e: any) => {
    const values = e.target.value?.split(",")?.map((value: any) => value?.trim());
    setSenders(values);
  };

  const handleReceiversChange = (e: any) => {
    const values = e.target.value?.split(",")?.map((value: any) => value?.trim());
    setReceivers(values);
  };

  //Teams
  const [selectedTeamsParticipant, setSelectedTeamsParticipant] = useState<any>([]);
  const [allTeamsAccounts, setAllTeamsAccounts] = useState<any>([]);

  const teamsAccountPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `filter/socialpost?social_type=TEAMS&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.length > 0) {
            var pagesOptions = res?.map((page: any, index: any) => ({
              value: page.id,
              label: page?.accountStatus === "DELETED" ? `${page.name} (Deleted)` : page.name,
            }));
            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...pagesOptions];
            setAllTeamsAccounts(options);
            resolve(options);
          } else {
            setAllTeamsAccounts([]);
            resolve([]);
            return errors;
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onTeamsAccountSelection = (selectedOption: any) => {
    const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];
    if (isSelectAllSelected) {
      arr = allTeamsAccounts
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOption;
    }

    setSelectedTeamsParticipant(arr);
  };

  //Zoom Chat
  const [selectedZoomParticipant, setSelectedZoomParticipant] = useState<any>([]);
  const [allZoomAccounts, setAllZoomAccounts] = useState<any>([]);

  const zoomAccountPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `filter/socialpost?social_type=ZOOM_CHAT&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.length > 0) {
            var pagesOptions = res?.map((page: any, index: any) => ({
              value: page.id,
              label: page?.accountStatus === "DELETED" ? `${page.name} (Deleted)` : page.name,
            }));
            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...pagesOptions];
            setAllZoomAccounts(options);
            resolve(options);
          } else {
            setAllZoomAccounts([]);
            resolve([]);
            return errors;
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onZoomAccountSelection = (selectedOption: any) => {
    const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];
    if (isSelectAllSelected) {
      arr = allZoomAccounts
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOption;
    }

    setSelectedZoomParticipant(arr);
  };

  //Website
  const [selectedWebsiteParticipant, setSelectedWebsiteParticipant] = useState<any>([]);
  const [allWebsiteAccounts, setAllWebsiteAccounts] = useState<any>([]);

  const websiteAccountPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `website/list?page=1&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.list?.length > 0) {
            var pagesOptions = res?.list?.map((page: any, index: any) => ({
              value: page.id,
              label: <TooltipCustom
                st={
                  <span>
                    {HelperService.truncate(page?.status === "DELETED" ? `${page.name} (Deleted)` : page.name, 50)}
                  </span>
                }
                message={page?.status === "DELETED" ? `${page.name} (Deleted)` : page.name}
                position="top"
                id="c_btn"
              ></TooltipCustom>
              ,
            }));
            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...pagesOptions];
            setAllWebsiteAccounts(options);
            resolve(options);
          } else {
            setAllWebsiteAccounts([]);
            resolve([]);
            return errors;
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onWebsiteAccountSelection = (selectedOption: any) => {
    const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];
    if (isSelectAllSelected) {
      arr = allWebsiteAccounts
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOption;
    }

    setSelectedWebsiteParticipant(arr);
  };


  //Slack
  const [selectedSlackParticipant, setSelectedSlackParticipant] = useState<any>([]);
  const [allSlackAccounts, setAllSlackAccounts] = useState<any>([]);

  const slackAccountPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.postAPI({
        action: `slack/channels?page=1&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.list?.length > 0) {
            var pagesOptions = res?.list?.map((page: any, index: any) => ({
              value: page.id,
              label: <TooltipCustom
                st={
                  <span>
                    {HelperService.truncate(page?.accountStatus === "DELETED" ? `${page.channelName} (Deleted)` : page.channelName, 50)}
                  </span>
                }
                message={page?.accountStatus === "DELETED" ? `${page.channelName} (Deleted)` : page.channelName}
                position="top"
                id="c_btn"
              ></TooltipCustom>
              ,
            }));
            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...pagesOptions];
            setAllSlackAccounts(options);
            resolve(options);
          } else {
            setAllSlackAccounts([]);
            resolve([]);
            return errors;
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onSlackAccountSelection = (selectedOption: any) => {
    const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];
    if (isSelectAllSelected) {
      arr = allSlackAccounts
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOption;
    }

    setSelectedSlackParticipant(arr);
  };

  //Social Media Apps 
  const [selectedFacebookAccount, setSelectedFacebookAccount] = useState<any[]>([]);
  const [allFacebookAccounts, setAllFacebookAccounts] = useState<any>([]);
  const [selectedInstagramAccount, setSelectedInstagramAccount] = useState<any[]>([]);
  const [allInstagramAccounts, setAllInstagramAccounts] = useState<any>([]);
  const [selectedLinkedInAccount, setSelectedLinkedInAccount] = useState<any[]>([]);
  const [allLinkedInAccounts, setAllLinkedInAccounts] = useState<any>([]);
  const [selectedTwitterPAccount, setSelectedTwitterPAccount] = useState<any[]>([]);
  const [allTwitterPAccounts, setAllTwitterPAccounts] = useState<any>([]);
  const [selectedTwitterBAccount, setSelectedTwitterBAccount] = useState<any[]>([]);
  const [allTwitterBAccounts, setAllTwitterBAccounts] = useState<any>([]);
  const [selectedYoutubeAccount, setSelectedYoutubeAccount] = useState<any[]>([]);
  const [allYoutubeAccounts, setAllYoutubeAccounts] = useState<any>([]);
  const [selectedStocktwitsAccount, setSelectedStocktwitsAccount] = useState<any[]>([]);
  const [allStocktwitsAccounts, setAllStocktwitsAccounts] = useState<any>([]);

  const onSocialAccountSelection = (selectedOption: any, type: string) => {
    if (type === "FACEBOOK") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allFacebookAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedFacebookAccount(arr);
    }

    if (type === "X_BUSINESS") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allTwitterBAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }
      setSelectedTwitterBAccount(arr);
    }

    if (type === "X_PERSONAL") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allTwitterPAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedTwitterPAccount(arr);
    }

    if (type === "LINKEDIN") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allLinkedInAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedLinkedInAccount(arr);
    }

    if (type === "INSTAGRAM") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allInstagramAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedInstagramAccount(arr);
    }

    if (type === "YOUTUBE") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allYoutubeAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedYoutubeAccount(arr);

    }

    if (type === "STOCKTWITS") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allStocktwitsAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedStocktwitsAccount(arr);
    }

  };

  const socialAccountPromiseOptions = (inputValue: any, social_type: string, account_type: string) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `filter/socialpost?social_type=${social_type}&account_type=${account_type}&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res.length > 0) {
            var instagramOption: any[] = [];
            var twitterOption: any[] = [];
            var facebookOption: any[] = [];
            var linkdnOption: any[] = [];
            var youtubeOption: any[] = [];
            var stocktwitsOption: any[] = [];

            res.forEach((channel: any) => {
              var obj: any = {
                value: channel.id,
                // label: (<span className="ms-3 font-14">{channel.name}</span>),
                label: channel?.accountStatus === "DELETED" ? `${channel.name} (Deleted)` : channel.name,
                social_type: channel.socialType,
                accountType: channel.accountType
              };

              if (channel.socialType === "INSTAGRAM") {
                instagramOption.push(obj);
                setAllInstagramAccounts(instagramOption);
              } else if (channel.socialType === "X") {
                twitterOption.push(obj);
                if (account_type === "PERSONAL") {
                  setAllTwitterPAccounts(twitterOption);
                } else {
                  setAllTwitterBAccounts(twitterOption);
                }
              } else if (channel.socialType === "FACEBOOK") {
                facebookOption.push(obj);
                setAllFacebookAccounts(facebookOption);
              } else if (channel.socialType === "LINKEDIN") {
                linkdnOption.push(obj);
                setAllLinkedInAccounts(linkdnOption);
              } else if (channel.socialType === "YOUTUBE") {
                youtubeOption.push(obj);
                setAllYoutubeAccounts(youtubeOption);
              } else if (channel.socialType === "STOCKTWITS") {
                stocktwitsOption.push(obj);
                setAllStocktwitsAccounts(stocktwitsOption);
              }

            });

            var groupedOptions: any = [];
            if (instagramOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={InstgramLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Instagram"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All", social_type: account_type }, ...instagramOption],
              });
            }
            if (twitterOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={Xlogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    X
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All", social_type: account_type }, ...twitterOption],
              });
            }
            if (facebookOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={FbLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Facebook"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All", social_type: account_type }, ...facebookOption],
              });
            }
            if (linkdnOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={linkdnLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Linkedin"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All", social_type: account_type }, ...linkdnOption],
              });
            }
            if (youtubeOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={youtubeLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"YouTube"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All", social_type: account_type }, ...youtubeOption],
              });
            }
            if (stocktwitsOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={stocktwitsLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Stocktwits"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All", social_type: account_type }, ...stocktwitsOption],
              });
            }

            resolve(groupedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const dateChange = (date_range: any) => {
    setValue("startDate", date_range.startDate);
    setValue("endDate", date_range.endDate);
    setdateRange(date_range);
  };


  const getIdList = (account: any) => {
    switch (account) {
      case "FACEBOOK": return selectedFacebookAccount.map((ele: any) => { return ele.value });
      case "INSTAGRAM": return selectedInstagramAccount.map((ele: any) => { return ele.value });
      case "LINKEDIN": return selectedLinkedInAccount.map((ele: any) => { return ele.value });
      case "ZOOM_CHAT": return selectedZoomParticipant.map((ele: any) => { return ele.value });
      case "XPERSONAL": return selectedTwitterPAccount.map((ele: any) => { return ele.value });
      case "XBUSINESS": return selectedTwitterBAccount.map((ele: any) => { return ele.value });
      case "TEAMS": return selectedTeamsParticipant.map((ele: any) => { return ele.value });
      case "YOUTUBE": return selectedYoutubeAccount.map((ele: any) => { return ele.value });
      case "STOCKTWITS": return selectedStocktwitsAccount.map((ele: any) => { return ele.value });
      case "WEBSITE": return selectedWebsiteParticipant.map((ele: any) => { return ele.value });
      case "SLACK": return selectedSlackParticipant.map((ele: any) => { return ele.value });
      case "BLOOMBERG": return selectedBloombergParticipant.map((ele: any) => { return ele.value });
      default: return []
    }
  }

  const [userOptions, setUserOptions] = useState<any>([]);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);


  const fetchUsers = async (inputValue: any) => {
    try {
      var userResponse: any = [];
      userResponse = await WebService.getAPI({
        action: `users/list?page=1&keyword=${inputValue}`,
        body: {},
        access_token: access_token,
        t_id: t_id
      });
      return userResponse?.list?.map((user: any) => ({
        value: user.id,
        label: user.first_name + " " + user.last_name,
        category: "user",
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  };
  const fetchGroups = async (inputValue: any) => {
    try {
      var groupResponse: any = [];
      groupResponse = await WebService.getAPI({
        action: `group-list?page=1&keyword=${inputValue}`,
        body: {},
        access_token: access_token,
        t_id: t_id
      });
      return groupResponse?.list?.map((group: any) => ({
        value: group.id,
        label: group.group_name,
        category: "group",
      }));
    } catch (error) {
      console.error("Error fetching groups:", error);
      return [];
    }
  }

  const loadOptions = async (inputValue: any, callback: any) => {
    try {
      const [userOptions, groupOptions] = await Promise.all([
        fetchUsers(inputValue),
        fetchGroups(inputValue),
      ]);
      setUserOptions(userOptions);
      setGroupOptions(groupOptions);

      const applyTo = [
        userOptions?.length > 0 &&
        { value: "all_users", label: (<label className="text-secondary font-14" >Select All Users</label>), category: "user" },
        ...userOptions,
        groupOptions?.length > 0 &&
        { value: "all_groups", label: (<label className="text-secondary font-14" >Select All Groups</label>), category: "group" },
        ...groupOptions,
      ];
      return applyTo?.filter((ele: any) => ele);
    } catch (error) {
      return [];
    }
  };

  const handleUserGroupChange = (selected: any) => {
    if (!selected) {
      setSelectedOptions([]);
      return;
    }

    const isAllUsersSelected = selected.some(
      (option: any) => option.value === "all_users"
    );
    const isAllGroupsSelected = selected.some(
      (option: any) => option.value === "all_groups"
    );

    if (isAllUsersSelected) {
      setSelectedOptions([
        ...userOptions.filter((option: any) => option.value !== "all_users"),
        ...selected.filter((option: any) => option.category !== "user"),
      ]);
    } else if (isAllGroupsSelected) {
      setSelectedOptions([
        ...groupOptions.filter((option: any) => option.value !== "all_groups"),
        ...selected.filter((option: any) => option.category !== "group"),
      ]);
    } else {
      setSelectedOptions(selected);
    }
  };


  //Bloomberg
  const [selectedBloombergParticipant, setSelectedBloombergParticipant] = useState<any>([]);
  const [allBloombergAccounts, setAllBloombergAccounts] = useState<any>([]);

  const bloombergAccountPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.postAPI({
        action: `bloomberg/channels?page=1&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.list?.length > 0) {
            var pagesOptions = res?.list?.map((page: any, index: any) => ({
              value: page.id,
              label: <TooltipCustom
                st={
                  <span>
                    {HelperService.truncate(page.channelName, 50)}
                  </span>
                }
                message={page.channelName}
                position="top"
                id="c_btn"
              ></TooltipCustom>
              ,
            }));
            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...pagesOptions];
            setAllBloombergAccounts(options);
            resolve(options);
          } else {
            setAllBloombergAccounts([]);
            resolve([]);
            return errors;
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onBloombergAccountSelection = (selectedOption: any) => {
    const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];
    if (isSelectAllSelected) {
      arr = allBloombergAccounts
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOption;
    }

    setSelectedBloombergParticipant(arr);
  };


  const handleCheckboxChange = (key: string, value: boolean) => {
    if (key === "Reviewed" && value) {
      setCheckbox((prev) => ({
        ...prev,
        Reviewed: true,
        notReviewed: false,
      }));
    } else if (key === "notReviewed" && value) {
      setCheckbox((prev) => ({
        ...prev,
        Reviewed: false,
        notReviewed: true,
      }));
    } else {
      setCheckbox((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };



  return (
    <>
      <Form
        className="w-100 form-style"
        name="Verify"
        id="Verify"
        onSubmit={handleSubmit(handleReviewAll, handleError)}
      >
        <div className="pt-3">
          <Row>
            <Col>
              <FloatingLabel
                controlId="ReviewPercentage"
                label="Review list name"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Review list name"
                  type="text"
                  maxLength={255}
                  {...register("name", {
                    required: true,
                  })}
                  onChange={(e: any) => {
                    handleChange("name", e.target.value);
                  }}
                />
                {errors.name && (
                  <Label
                    title={
                      errors.name.message ? errors.name.message.toString() : ""
                    }
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                )}
              </FloatingLabel>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="mb-3">
            <div className="d-flex gap-1">
              <Col lg={4}>
                <InputGroup className="perce-adon custom-input-group">
                  <Form.Control
                    value={"Review sample"}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className="font-12 font-medium"
                    style={{ fontSize: "14px" }}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <span className="">{watchVariable.percentage}</span>{" "}
                  </InputGroup.Text>
                </InputGroup>

              </Col>
              <Col lg={4}>
                <FloatingLabel controlId="reviewSample" label="Review sample" className="">
                  <Form.Select
                    aria-label="Select"
                    {...register("sampleReview", {
                      required: "Please select review sample type",
                    })}
                  >
                    <option
                      value=""
                      disabled={true}
                    >
                      Select type
                    </option>
                    <option value="COUNT">Count</option>
                    <option value="PERCENTAGE">Percentage</option>
                  </Form.Select>
                </FloatingLabel>
                {errors.sampleReview && (
                  <Label
                    title={errors.sampleReview.message?.toString()}
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                )}
              </Col>

              <Col>
                <FloatingLabel controlId="ReviewPercentage" label={`Review sample`} className="">
                  <Form.Control
                    type="text"
                    {...register("percentage", {
                      required: "Please enter value",
                      validate: (value) => {
                        if (watchVariable.sampleReview === "PERCENTAGE") {
                          if (value === "") return "Please enter value";
                          if (!/^[1-9]\d?$|^100$/.test(value)) {
                            return "Please enter a valid number between 1 and 100";
                          }
                        }
                        return true;
                      },
                    })}
                    defaultValue={10}
                    onChange={(e: any) => {
                      const enteredValue = e.target.value;
                      if (watchVariable.sampleReview === "PERCENTAGE" && parseInt(enteredValue) > 100) {
                        setError("percentage", {
                          type: "custom",
                          message: "Please enter a valid number between 1 and 100",
                        });
                      } else {
                        clearErrors("percentage");
                        setValue("percentage", enteredValue);
                      }
                    }}
                  />
                  {errors.percentage && (
                    <Label
                      title={errors.percentage.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </FloatingLabel>
              </Col>

            </div>
          </div>
        </Row>
        <div className="">
          <div className="">
            <div>
              <WMDateRangePicker
                selectData={(date_range: any) => {
                  dateChange(date_range);
                }}
                date_range={date_range}
              >
              </WMDateRangePicker>
            </div>
            <div style={{ marginLeft: "7px" }}>

            </div>
          </div>
          <div className="mb-1">
            {errors.endDate && (
              <Label
                title={
                  errors.endDate.message?.toString() ?? "Please select end date"
                }
                modeError={true}
                showStar={true}
                type=""
              />
            )}
          </div>

          <div className="mb-3">
            <AsyncSelect
              cacheOptions
              defaultOptions
              onChange={onConnectorSelection}
              isMulti
              loadOptions={connectorPromiseOptions}
              placeholder="Select channels"
              value={connectors}
            />
            <span className="text-danger font-12">{connectorError}</span>
          </div>

          <div className="mb-3">
            <AsyncSelect
              isMulti
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              onChange={handleUserGroupChange}
              placeholder="Select Users or Groups"
              value={selectedOptions}
            />
          </div>


          <Row className="mb-3">
            <Col>
              <Form.Check
                type="checkbox"
                id="aiFlagging"
                label="AI Compliance Review"
                onChange={() => {
                  setAiFlaggingEnabled((prev: any) => { return !prev });
                }}
                defaultChecked={false}
              />
            </Col>
          </Row>

          <div className=" mb-3">
            <div className="px-3 mb-1">
              <Row className="row-cols-4 mb-2 d-flex gap-2" >
                <div className="px-2 py-1 bg-danger-subtle">
                  <Form.Check
                    type="checkbox"
                    name="Flagged"
                    id="Flagged"
                    className="labe-text-dark checkbox-danger"
                    label="Flagged"
                    checked={checkbox.Flagged}
                    onChange={(event) =>
                      handleCheckboxChange("Flagged", event?.target?.checked)
                    }
                  />
                </div>
                <div className="px-2 py-1 bg-success-subtle">
                  <Form.Check
                    type="checkbox"
                    name="Reviewed"
                    className="labe-text-dark checkbox-success"
                    id="Reviewed"
                    label="Reviewed"
                    checked={checkbox.Reviewed}
                    onChange={(event) =>
                      handleCheckboxChange("Reviewed", event?.target?.checked)
                    }
                  />
                </div>
                <div className="px-2 py-1 bg-warning-subtle">
                  <Form.Check
                    type="checkbox"
                    name="escalateAndSend"
                    id="escalateAndSend"
                    className="labe-text-dark checkbox-warning"
                    label="Escalate"
                    checked={checkbox.escalateAndSend}
                    onChange={(event) =>
                      handleCheckboxChange("escalateAndSend", event?.target?.checked)
                    }
                  />
                </div>
              </Row>
              <Row className="mb-2 d-flex gap-2">
                <div className="px-2 py-1 bg-primary-subtle col-6 text-nowrap">
                  <Form.Check
                    type="checkbox"
                    name="Reviewed2"
                    className="labe-text-dark checkbox-primary "
                    id="Reviewed2"
                    label="Flagged but not reviewed"
                    checked={checkbox.flaggedNotReviewed}
                    onChange={(event) =>
                      handleCheckboxChange("flaggedNotReviewed", event?.target?.checked)
                    }
                  />
                </div>
                <div className="px-2 py-1 bg-secondary-subtle col-4 text-nowrap">
                  <Form.Check
                    type="checkbox"
                    name="notReviewed"
                    className="labe-text-dark checkbox-secondary"
                    id="notReviewed"
                    label="Not yet reviewed"
                    checked={checkbox.notReviewed}
                    onChange={(event) =>
                      handleCheckboxChange("notReviewed", event?.target?.checked)
                    }
                  />
                </div>
              </Row>

            </div>
          </div>

          {(connectors?.some((item: any) => item?.value === "TEAMS")) &&
            <>
              <div className=" mb-3">
                <h6>Teams</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onTeamsAccountSelection(value) }}
                    isMulti
                    loadOptions={teamsAccountPromiseOptions}
                    placeholder="Select Account"
                    value={selectedTeamsParticipant}
                  />
                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "EMAIL")) &&
            <>
              <div className="mb-3">
                <h6>Email</h6>
                <div className="mt-1 mb-3">
                  <Controller
                    name="emailSender"
                    control={control}
                    rules={{
                      validate: (value: string) => {
                        const emailArray = value?.split(',')?.map((email) => email?.trim());
                        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        if (emailArray?.filter((value: string) => value !== "")?.length > 0) {
                          for (const email of emailArray) {
                            if (!emailRegex.test(email)) {
                              return "Each email address must be valid and separated by commas.";
                            }
                          }
                          return true;
                        }
                      },
                    }}
                    render={({ field }) => (
                      <FloatingLabel controlId="senders" label="Senders">
                        <Form.Control
                          type="text"
                          onChange={(value: any) => {
                            field.onChange(value);
                            handleEmailSendersChange(value);
                          }}
                          placeholder="Senders"
                          value={emailSenders}
                        />
                      </FloatingLabel>
                    )}
                  />
                  {errors.emailSender && (
                    <Label
                      title={errors.emailSender.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </div>

                <div className="mt-1 mb-3">
                  <Controller
                    name="emailReceiver"
                    control={control}
                    rules={{
                      validate: (value: string) => {
                        const emailArray = value?.split(',')?.map((email) => email?.trim());
                        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        if (emailArray?.filter((value: string) => value !== "")?.length > 0) {
                          for (const email of emailArray) {
                            if (!emailRegex.test(email)) {
                              return "Each email address must be valid and separated by commas.";
                            }
                          }
                          return true;
                        }
                      },
                    }}
                    render={({ field }) => (
                      <FloatingLabel controlId="receiver" label="Receivers">
                        <Form.Control
                          type="text"
                          onChange={(value: any) => {
                            field.onChange(value);
                            handleEmailReceiversChange(value);
                          }}
                          placeholder="Receivers"
                          value={emailReceivers}
                        />
                      </FloatingLabel>
                    )}
                  />
                  {errors.emailReceiver && (
                    <Label
                      title={errors.emailReceiver.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </div>
              </div>
            </>
          }

          {((connectors?.some((item: any) => item?.value === "SMS")) ||
            (connectors?.some((item: any) => item?.value === "IMESSAGE")) ||
            (connectors?.some((item: any) => item?.value === "MY_REPCHAT")) ||
            (connectors?.some((item: any) => item?.value === "ZOOM_SMS")) ||
            (connectors?.some((item: any) => item?.value === "WHATSAPP"))
          ) &&
            <>
              <div className=" mb-3">
                <h6>SMS / MyRepChat / iMessage / Zoom SMS / WhatsApp / Other SMS</h6>

                <div className="mt-1 mb-3">
                  <Controller
                    name="smsSender"
                    control={control}
                    rules={{
                      validate: (value: string) => {
                        const numberArray = value?.split(',')?.map((number) => number?.trim());
                        const phoneRegex = /^\+?(\d{1,4}[-.\s]?)?(\(?\d{1,3}\)?[-.\s]?)?(\d{1,4}[-.\s]?)?(\d{1,4}[-.\s]?)?(\d{1,4})?$/;
                        if (numberArray?.length > 0) {
                          for (const number of numberArray) {
                            if (!phoneRegex.test(number)) {
                              return "Each phone number must be valid and separated by commas.";
                            }
                          }
                          return true;
                        }
                      },
                    }}
                    render={({ field }) => (
                      <FloatingLabel controlId="senders" label="Senders">
                        <Form.Control
                          type="text"
                          onChange={(value: any) => {
                            field.onChange(value);
                            handleSendersChange(value);
                          }}
                          placeholder="Senders"
                          value={senders}
                        />
                      </FloatingLabel>
                    )}
                  />
                  {errors.smsSender && (
                    <Label
                      title={errors.smsSender.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </div>

                <div className="mt-1 mb-3">
                  <Controller
                    name="smsReceiver"
                    control={control}
                    rules={{
                      validate: (value: string) => {
                        const numberArray = value?.split(',')?.map((number) => number?.trim());
                        const phoneRegex = /^\+?(\d{1,4}[-.\s]?)?(\(?\d{1,3}\)?[-.\s]?)?(\d{1,4}[-.\s]?)?(\d{1,4}[-.\s]?)?(\d{1,4})?$/;
                        if (numberArray?.length > 0) {
                          for (const number of numberArray) {
                            if (!phoneRegex.test(number)) {
                              return "Each phone number must be valid and separated by commas.";
                            }
                          }
                          return true;
                        }
                      },
                    }}
                    render={({ field }) => (
                      <FloatingLabel controlId="receiver" label="Receivers">
                        <Form.Control
                          type="text"
                          onChange={(value: any) => {
                            field.onChange(value);
                            handleReceiversChange(value);
                          }}
                          placeholder="Receivers"
                          value={receivers}
                        />
                      </FloatingLabel>
                    )}
                  />
                  {errors.smsReceiver && (
                    <Label
                      title={errors.smsReceiver.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </div>

              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "ZOOM_CHAT")) &&
            <>
              <div className=" mb-3">
                <h6>Zoom Chat</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onZoomAccountSelection(value) }}
                    isMulti
                    loadOptions={(inputValue: string) => zoomAccountPromiseOptions(inputValue)}
                    placeholder="Select Account"
                    value={selectedZoomParticipant}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "FACEBOOK")) &&
            <>
              <div className=" mb-3">
                <h6>Facebook</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "FACEBOOK") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "FACEBOOK", "BUSINESS")}
                    placeholder="Select Account"
                    value={selectedFacebookAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "X_BUSINESS")) &&
            <>
              <div className=" mb-3">
                <h6>X Business</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "X_BUSINESS") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "X", "BUSINESS")}
                    placeholder="Select Account"
                    value={selectedTwitterBAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "X_PERSONAL")) &&
            <>
              <div className=" mb-3">
                <h6>X Personal</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "X_PERSONAL") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "X", "PERSONAL")}
                    placeholder="Select Account"
                    value={selectedTwitterPAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "LINKEDIN")) &&
            <>
              <div className=" mb-3">
                <h6>LinkedIn</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "LINKEDIN") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "LINKEDIN", "")}
                    placeholder="Select Account"
                    value={selectedLinkedInAccount}
                  />
                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "INSTAGRAM")) &&
            <>
              <div className=" mb-3">
                <h6>Instagram</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "INSTAGRAM") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "INSTAGRAM", "")}
                    placeholder="Select Account"
                    value={selectedInstagramAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "YOUTUBE")) &&
            <>
              <div className=" mb-3">
                <h6>YouTube</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "YOUTUBE") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "YOUTUBE", "PERSONAL")}
                    placeholder="Select Account"
                    value={selectedYoutubeAccount}
                  />
                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "STOCKTWITS")) &&
            <>
              <div className=" mb-3">
                <h6>Stocktwits</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "STOCKTWITS") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "STOCKTWITS", "BUSINESS")}
                    placeholder="Select Account"
                    value={selectedStocktwitsAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "WEBSITE")) &&
            <>
              <div className=" mb-3">
                <h6>Website</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onWebsiteAccountSelection(value) }}
                    isMulti
                    loadOptions={(inputValue: string) => websiteAccountPromiseOptions(inputValue)}
                    placeholder="Select Websites"
                    value={selectedWebsiteParticipant}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "SLACK")) &&
            <>
              <div className=" mb-3">
                <h6>Slack</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSlackAccountSelection(value) }}
                    isMulti
                    loadOptions={(inputValue: string) => slackAccountPromiseOptions(inputValue)}
                    placeholder="Select Channels"
                    value={selectedSlackParticipant}
                  />

                </div>
              </div>
            </>
          }

          {/* {(connectors?.some((item: any) => item?.value === "BLOOMBERG")) &&
            <>
              <div className=" mb-3">
                <h6>Bloomberg</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onBloombergAccountSelection(value) }}
                    isMulti
                    loadOptions={(inputValue: string) => bloombergAccountPromiseOptions(inputValue)}
                    placeholder="Select Channels"
                    value={selectedBloombergParticipant}
                  />
                </div>
              </div>
            </>
          } */}

          <Button type="submit" className="btn btn-brand-1 w-100" id="btn-save">
            Start Review
          </Button>
          <span className="mt-2 text-danger font-12">Note: The "Review All" feature will not include messages that are already flagged or escalated.</span>
        </div>
      </Form>
    </>
  );
};

export default ReviewAllFilterBlade;


























































































