import { configureStore, combineReducers } from '@reduxjs/toolkit';
import profileReducer from './Reducer/ProfileReducer';
import userRoleReducer from './Reducer/userRoleReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

//encryptor
const encryptor = encryptTransform({
  secretKey: 'letni$321987@Evihcra', 
  onError: function (error) {   
    console.error(error);
  },
});

const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor], 
};

const rootReducer = combineReducers({
  pI: profileReducer, //profileImage
  uR: userRoleReducer, //userRole
  uI: profileReducer,  //userId 
  uN: profileReducer,  //userName 
  tfa: profileReducer,  //2faenable
  uE :profileReducer,  //userEmail
  cI:profileReducer,   //clientId  
  uat:profileReducer,
  iA:profileReducer,  //inboundArchiving   
  oA:profileReducer,   //outboundArchiving  
  aT:profileReducer,    //accessToken 
  tI:profileReducer,  //tenantId
  aiM:profileReducer,  //aiModel
  cF:profileReducer,    //comeFrom  
  uqI:profileReducer,  //uniqueId
  aCE:profileReducer,   //aiChatbotEnabled 
  vCE:profileReducer,   //vaultEnabled
  sUrl:profileReducer,   //salesforceUrl
  oS:profileReducer,   //otherEmailSMS
  iAuto:profileReducer,   //iMessageAutomation

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;


